import React from "react";
import { createRoot } from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ReduxStore from "./redux/store";
import { Provider } from "react-redux";

const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={ReduxStore}>
    <Router>
      <App />
    </Router>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
