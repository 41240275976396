import { LEAVE } from "../Action";

const intialState = { holiday: "" };

const LeaveReducer = (state = intialState, action) => {
  switch (action.type) {
    case LEAVE.holiday: {
      return { ...state, holiday: action.payload };
    }
    case LEAVE.getholiday: {
      return { ...state, getholiday: action.payload };
    }
    case LEAVE.updateholiday: {
      return { ...state, updateholiday: action.payload };
    }
    case LEAVE.deleteholiday: {
      return { ...state, deleteholiday: action.payload };
    }
    case LEAVE.year: {
      return { ...state, year: action.payload };
    }
    default:
      break;
  }
  return state;
};

export default LeaveReducer;
