import { Alert, Snackbar } from "@mui/material";
import React from "react";

function SnackBar(props) {
  const {
    openValidation,
    handleCloseValidation,
    snackbarMessageValidation,
    severity,
  } = props;
  return (
    <Snackbar
      open={openValidation}
      autoHideDuration={3000}
      onClose={handleCloseValidation}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        sx={{ fontWeight: 600 }}
        onClose={handleCloseValidation}
        severity={severity}
        variant="filled"
      >
        {snackbarMessageValidation}.
      </Alert>
    </Snackbar>
  );
}

export default SnackBar;
