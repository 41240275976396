import React, { useEffect, useState } from "react";
import Spinner from "../../utils/Spinner/Spinner";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { adminLogin } from "../../redux/action/login";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "../../redux/action/employee";
import SnackBar from "../../utils/snackBar";

export default function JumpLoad() {
  const dispatch = useDispatch();
  const [snackbarMessageValidation, setSnackBarMessageValidation] =
    useState("");
  const [openValidation, setOpenValidation] = useState(false);
  const location = useLocation();
  const [severity, setSeverity] = useState("error");
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code)
      dispatch(
        adminLogin(code, (res) => {
          if (res.status === 200) {
            window.localStorage.setItem("accessToken", res.data.accessToken);
            window.localStorage.setItem(
              "userProduct",
              JSON.stringify(res.data)
            );
            dispatch(
              MenuItem((response) => {
                if (response.status === 200) {
                  navigate(response?.data[0].path);
                } else {
                  setOpenValidation(true);
                  setSeverity("success");
                  setSnackBarMessageValidation(response.message);
                }
              })
            );
          } else {
            setOpenValidation(true);
            setSeverity("error");
            setSnackBarMessageValidation(res.message);
          }
        })
      );
  }, []);

  const handleCloseValidation = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenValidation(false);
  };

  return (
    <Grid
      className="spinner-height-set"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      top={"-33px"}
      position={"relative"}
    >
      <Spinner />
      <SnackBar
        snackbarMessageValidation={snackbarMessageValidation}
        openValidation={openValidation}
        handleCloseValidation={handleCloseValidation}
        severity={severity}
      />
    </Grid>
  );
}
