import { EMPLOYEE } from "../Action";

const intialState = {
  employee: null,
  getemployee: null,
  getReportingManager: [],
  getRoles: [],
  menuItem: null,
};
const EmployeeReducer = (state = intialState, action) => {
  switch (action.type) {
    case EMPLOYEE.employee:
      return { ...state, employee: action.payload };
    case EMPLOYEE.getemployee:
      return { ...state, getemployee: action.payload };
    case EMPLOYEE.getReportingManager:
      return { ...state, getReportingManager: action.payload };
    case EMPLOYEE.getRoles:
      return { ...state, getRoles: action.payload };
    case EMPLOYEE.updateEmployee:
      return { ...state, updateEmployee: action.payload };
    case EMPLOYEE.deleteEmployee:
      return { ...state, deleteEmployee: action.payload };
    case EMPLOYEE.menuItem:
      return { ...state, menuItem: action.payload };
    default:
      break;
  }
  return state;
};

export default EmployeeReducer;
