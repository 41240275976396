import { ADMIN } from "../Action";

const intialState = {
  adminLogin: [],
};
const AdminReducer = (state = intialState, action) => {
  if (ADMIN.adminLogin) {
    return {
      ...state,
      adminLogin: action.paylaod,
    };
  } else {
    return state;
  }
};

export default AdminReducer;
