// import axios from "axios";
// import axios from "../../components/Axios/Axios";

import { DEFAULT_URL } from "../../components/api/API";
import { DELETE, GET, POST, PUT } from "../../components/axios/Axios";
import { catchError } from "../../utils/Helper";
import { EMPLOYEE } from "../Action";

export const employeeDetails = (data, callback) => {
  return (dispatch) => {
    POST(
      `${DEFAULT_URL}/employee`,
      data,
      {},
      (response) => {
        dispatch({
          type: EMPLOYEE.employee,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};

export const getEmployeeDetails = (
  page,
  selectRole,
  nameselect,
  order,
  callback = () => {}
) => {
  let sortOrder;
  if (order === "asc") {
    sortOrder = "ASCENDING";
  } else if (order === "") {
    sortOrder = order;
  } else {
    sortOrder = "DESCENDING";
  }
  return (dispatch) => {
    GET(
      `${DEFAULT_URL}/employee`,
      {
        page: page,
        role: selectRole == "All" ? "" : selectRole,
        employeeName: nameselect,
        sort: sortOrder,
      },
      (response) => {
        dispatch({
          type: EMPLOYEE.getemployee,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};

export const getRoleOptions = (callback = () => {}) => {
  return (dispatch) => {
    GET(
      `${DEFAULT_URL}/role`,
      {},
      (response) => {
        dispatch({
          type: EMPLOYEE.getRoles,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};

export const getReportingManagers = (callback = () => {}) => {
  return (dispatch) => {
    GET(
      `${DEFAULT_URL}/employeeName`,
      {},
      (response) => {
        dispatch({
          type: EMPLOYEE.getReportingManager,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};

export const updateEmployeeDetails = (data, id, callback = () => {}) => {
  return (dispatch) => {
    PUT(
      `${DEFAULT_URL}/employee/${id}`,
      data,
      {},
      (response) => {
        dispatch({
          type: EMPLOYEE.updateEmployee,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};

export const deleteEmployeeDetails = (id, callback = () => {}) => {
  return (dispatch) => {
    DELETE(`${DEFAULT_URL}/employee/${id}`, {}, (response) => {
      dispatch({
        type: EMPLOYEE.deleteEmployee,
        payload: response.data,
      });
      callback(response.data);
    });
  };
};

export const MenuItem = (callback = () => {}) => {
  return (dispatch) => {
    GET(
      `${DEFAULT_URL}/menu`,
      {},
      (response) => {
        dispatch({
          type: EMPLOYEE.menuItem,
          payload: response.data,
        });
        callback(response.data);
      },
      (error) => {
        callback(catchError(error));
      }
    );
  };
};
