import { lazy } from "react";
import { Navigate } from "react-router-dom";
import JumpLoad from "../components/jump-cloud/JumpCloud.js";

const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const Dashboard = lazy(() => import("../components/dashboard/Index.js"));
const TotalTasks = lazy(() =>
  import("../components/dashboard/IndexTotalTasks.js")
);
const Login = lazy(() => import("../components/login/Login.js"));

const Table = lazy(() => import("../components/views/leave/index.js"));
const EmployeeManage = lazy(() =>
  import("../components/views/employee/index.js")
);
const CustomerManagement = lazy(() =>
  import("../components/customer-management/index.js")
);
const AddCustomerDetailsPage = lazy(() =>
  import("../components/customer-management/AddCustomerDetails/index.js")
);

const TaskTracker = lazy(() => import("../components/task-tracker/Index.js"));
const Profile = lazy(() => import("../layouts/side-bar/profile.js"));
const SuperAdmin = lazy(() => import("../components/super-admin/index.js"));
const AuditTrail = lazy(() => import("../components/audit-trail/index.js"));
const jumpload = lazy(() => import("../components/jump-cloud/JumpCloud.js"));
const TasksManagement = lazy(() =>
  import("../components/task-management/index.js")
);
const ReassignTable = lazy(() =>
  import("../components/task-management/reassign/index.js")
);

const DataManagement = lazy(() =>
  import("../components/data-management/index.js")
);

const ThemeRoutes = [
  {
    path: "/",
    element: <Navigate to="/login" />,
    children: [],
  },
  {
    path: "/login",
    element: <Login />,
    children: [],
  },
  { path: "/callback", element: <JumpLoad /> },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },

      { path: "/employeeManagement", element: <EmployeeManage /> },
      { path: "/leaveManagement", element: <Table /> },
      { path: "/customerManagement", element: <CustomerManagement /> },
      {
        path: "/customerManagement/addcustomerdetails",
        element: <AddCustomerDetailsPage />,
      },
      {
        path: "/dashboard/taskspage",
        element: <TotalTasks />,
      },
      {
        path: "/dashboard/tasktracker",
        element: <TaskTracker />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/analyticsReports",
        element: <SuperAdmin />,
      },
      {
        path: "/auditTrail",
        element: <AuditTrail />,
      },
      {
        path: "/taskManagement",
        element: <TasksManagement />,
      },
      {
        path: "/taskManagement/reassigntasks",
        element: <ReassignTable />,
      },
      {
        path: "/dataManagement",
        element: <DataManagement />,
      },
      // { path: "/callback", element: <JumpLoad /> },
    ],
  },
];

export default ThemeRoutes;
