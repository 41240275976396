export const ADMIN = {
  adminLogin: "adminLogin",
};

export const EMPLOYEE = {
  employee: "employee",
  getemployee: "getemployee",
  getReportingManager: "getReportingManager",
  getRoles: "getRoles",
  updateEmployee: "updateEmployee",
  deleteEmployee: "deleteEmployee",
  menuItem: "menuItem",
};

export const LEAVE = {
  holiday: "holiday",
  getholiday: "getholiday",
  updateholiday: "updateholiday",
  deleteholiday: "deleteholiday",
  year: "year",
};

export const CUSTOMER = {
  personal: "personal",
  assignee: "assignee",
  table: "table",
  getpersonal: "getpersonal",
  getfinancialdata: "getfinancialdata",
  getteam: "getteam",
  deleteCustomer: "deleteCustomer",
  updateCustomer: "updateCustomer",
  srmassignee: "srmassignee",
};

export const DASHBOARD = {
  getdashboard: "getdashboard",
  gettotaltask: "gettotaltask",
  getperformance: "getperformance",
  getdashboardchart: "getdashboardchart",
};

export const TASKTRACKER = {
  timeline: "timeline",
  document: "document",
  customerdocument: "customerdocument",
  customerdetails: "customerdetails",
  documentsave: "documentsave",
  report: "report",
  customergetreport: "customergetreport",
  deleteReport: "deleteReport",
  deleteDocument: "deleteDocument",
  tasknames: "tasknames",
  taskreject: "taskreject",
  saveclarification: "saveclarification",
  allcomment: "allcomment",
  getclarificationcomment: "getclarificationcomment",
  getrejectioncomment: "getrejectioncomment",
  getopsnames: "getopsnames",
  docassign: "docassign",
  emailclient: "emailclient",
  reportsent: "reportsent",
  taskapprove: "taskapprove",
  viewclari: "viewclari",
  approveBtn: "approveBtn",
  holdforthismonth: "holdforthismonth",
  documentcommentsave: "documentcommentsave",
  getemailclient: "getemailclient",
  viewreject: "viewreject",
  documentVerification: "documentVerification",
  processAll: "processAll",
  clarificationdocumentupload: "clarificationdocumentupload",
  getActive: "getActive",
};

export const SUPERADMIN = {
  reportingtable: "reportingtable",
  reportingtableexport: "reportingtableexport",
  analyticsReport: "analyticsReport",
  analyticsreportexport: "analyticsreportexport",
  breachanalysis: "breachanalysis",
  cumulativeanalysis: "cumulativeanalysis",
  taskcompletion: "taskcompletion",
  taskcompletionprogress: "taskcompletionprogress",
  taskcompletionprogressexport: "taskcompletionprogressexport",
  bestperformertask: "bestperformertask",
  delayanalysis: "delayanalysis",
  delayanalysisexport: "delayanalysisexport",
};

export const AUDITTRAIL = {
  getlogdetails: "getlogdetails",
  exportlog: "exportlog",
};

export const NOTIFICATION = {
  getmailnotifcation: "getmailnotifcation",
  getpostmailnotifcation: "getpostmailnotifcation",
  deletemailnotification: "deletemailnotification",
};

export const TASKMANAGEMENT = {
  getalltasks: "getalltasks",
  getemployeetasks: "getemployeetasks",
  getemployeenames: "getemployeenames",
  reassigntasks: "reassigntasks",
};

export const DATAMANAGEMENT = {
  getfamilynames: "getfamilynames",
};
