import { combineReducers } from "redux";
import AdminReducer from "./reducer/AdminReducer";
import EmployeeReducer from "./reducer/EmployeeReducer";
import LeaveReducer from "./reducer/leaveReducer";
import CustomerReducer from "./reducer/CustomerReducer";

const Reducer = combineReducers({
  Admin: AdminReducer,
  Employee: EmployeeReducer,
  Leave: LeaveReducer,
  Customer: CustomerReducer,
});

export default Reducer;
