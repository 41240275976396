import axios from "axios";

import { ADMIN } from "../Action";
import { DEFAULT_URL } from "../../components/api/API";
import { catchError } from "../../utils/Helper";

const BASE_URL = process.env.REACT_APP_API_URL;
console.log(BASE_URL, process.env);

export const adminLogin = (data, callback = () => {}) => {
  console.log(BASE_URL, data, "lkk");
  return (dispatch) => {
    axios
      .post(
        `${DEFAULT_URL}/jumpcloud`,
        {},
        {
          crossDomain: true,
          headers: {
            code: data,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        dispatch({ type: ADMIN.adminLogin, payload: result.data });
        callback(result);
      })
      .catch((error) => {
        callback(catchError(error));
      });
  };
};
