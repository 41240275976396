import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./assets/global/Theme-variable";
import Themeroutes from "./routes/Router";
import "./assets/css/app.css";

const App = () => {
  const routing = useRoutes(Themeroutes);
  const theme = baseTheme;
  return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
};

export default App;
