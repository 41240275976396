import { CUSTOMER } from "../Action";

const intialState = {
  personal: null,
  getpersonal: {},
  getteam: {},
  getfinancialdata: {},
  updateCustomer: {},
};
const CustomerReducer = (state = intialState, action) => {
  switch (action.type) {
    case CUSTOMER.personal:
      return { ...state, personal: action.payload };
    case CUSTOMER.assignee:
      return { ...state, assignee: action.payload };
    case CUSTOMER.table:
      return { ...state, table: action.payload };
    case CUSTOMER.getpersonal:
      return { ...state, getpersonal: action.data };
    case CUSTOMER.getfinancialdata:
      return { ...state, getfinancialdata: action.data };
    case CUSTOMER.getteam:
      return { ...state, getteam: action.payload };
    case CUSTOMER.updateCustomer:
      return { ...state, updateCustomer: action.payload };
    case CUSTOMER.srmassignee: {
      return { ...state, srmassignee: action.payload };
    }
    default:
      break;
  }
  return state;
};

export default CustomerReducer;
